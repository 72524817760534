import { Link } from 'gatsby'
import styled from 'styled-components'
import Colours from './colours'

const Button = styled(Link)`
  background: ${props =>
    props.brand ? Colours.brand : props.accent ? Colours.accent : '#333'};
  padding: ${props => (props.small ? '.5em 1em' : '1em 2em')};
  color: #fff;
  border-radius: 0.5em;
  transition: all 0.5s ease;
  &:hover {
    text-decoration: none;
    color: white;
  }
`

const Submit = styled.button`
  background: ${Colours.accent};
  padding: 0.6em 2em;
  color: #fff;
  border: none;
  border-radius: 0.5em;
  transition: all 0.5s ease;
  cursor: pointer;
  &:hover {
    color: #fff;
    text-decoration: none;
  }
`
export { Button, Submit }
