import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Logo from '../images/logo.png'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

const StyledNavbar = styled(Navbar)`
  background-color: #fff;
  font-size: 1.1em;
  .logo {
    height: 200px;
  }
  .nav-link {
    text-align: center;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .navbar-brand {
    margin: 0;
  }
  .navbar-toggler {
    border: 0;
  }
  .navbar-nav {
    text-align: center;
  }
  .dropdown-menu {
    border: none;
  }
  @media (min-width: 769px) {
    flex-flow: column nowrap !important;
  }
  @media (max-width: 768px) {
    .logo {
      height: 100px;
    }
    .nav-link {
      padding-right: 1.3rem;
      padding-left: 1.3rem;
    }
  }
`

export default class Example extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }
  render() {
    return (
      <>
        <StyledNavbar light expand="md">
          <NavbarBrand tag={Link} to="/">
            <img className="logo" src={Logo} alt="Supreme cleaning logo" />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav navbar>
              <NavItem>
                <NavLink activeClassName="active" tag={Link} to="/">
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink activeClassName="active" tag={Link} to="/about">
                  About Us
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Services
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>
                    <NavLink
                      activeClassName="active"
                      tag={Link}
                      to="/services/domestic"
                    >
                      Domestic Window Cleaning
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink
                      activeClassName="active"
                      tag={Link}
                      to="/services/commercial"
                    >
                      Commercial Window Cleaning
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink
                      activeClassName="active"
                      tag={Link}
                      to="/services/fascia-and-soffit-cleaning"
                    >
                      Fascia & Soffit Cleaning
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink
                      activeClassName="active"
                      tag={Link}
                      to="/services/gutter-clearance"
                    >
                      Gutter Clearance
                    </NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink activeClassName="active" tag={Link} to="/contact">
                  Contact
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </StyledNavbar>
      </>
    )
  }
}
