import React from 'react'
import { FaFacebook, FaTwitter, FaPhone, FaInstagram } from 'react-icons/fa'
import styled from 'styled-components'

const StyledFooter = styled.footer`
  padding: 3em 0;
  background-color: steelBlue;
  margin-top: 3em;
  color: #fff;
  font-size: 1.1em;
  text-align: center;
  a {
    color: #fff;
    &:hover {
      color: #eee;
    }
  }
`
const Footer = () => (
  <StyledFooter>
    <div className="container">
      <a
        href="https://www.facebook.com/SupremeCleaningEssexandLondon"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaFacebook style={{ width: '25px', height: '25px', margin: '10px' }} />
      </a>
      <a
        href="https://twitter.com/CleanSupremeUK"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaTwitter style={{ width: '25px', height: '25px', margin: '10px' }} />
      </a>
      <a
        href="https://www.instagram.com/supremecleaningessex/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaInstagram
          style={{ width: '25px', height: '25px', margin: '10px' }}
        />
      </a>
      <p>
        © {new Date().getFullYear()} Supreme Cleaning. Web design by
        {` `}
        <a href="https://www.kozocreative.com">Kozo Creative</a>
      </p>
      <h3>
        <FaPhone /> 01245 471 731
      </h3>
    </div>
  </StyledFooter>
)

export default Footer
