import React from 'react'
import styled from 'styled-components'
import { Submit } from './button'

const Form = styled.form`
  max-width: 600px;
  margin: 0 auto;
  .hidden {
    display: none;
  }
`
const StyledForm = props => (
  <Form name={props.name} method="POST" data-netlify="true">
    <input type="hidden" name="form-name" value={props.name} />
    <div className="form-group">
      <label htmlFor="name">Name</label>
      <input
        type="text"
        className="form-control"
        name="name"
        placeholder="Name"
        required
      />
    </div>
    <div className="form-group">
      <label htmlFor="email">Email Address</label>
      <input
        type="email"
        className="form-control"
        name="email"
        placeholder="Email"
        required
      />
    </div>
    <div className="form-group">
      <label htmlFor="email">Postcode</label>
      <input
        type="text"
        className="form-control"
        name="postcode"
        placeholder="The first part will do. E.g. 'CM14'"
      />
    </div>
    <div className="form-group">
      <label htmlFor="message">Leave a note</label>
      <textarea
        className="form-control"
        name="message"
        placeholder="Ask a question, request a quote, or just say hi!"
      />
    </div>
    <Submit className="btn-block" type="submit">
      Submit
    </Submit>
  </Form>
)
export default StyledForm
